import { StaticImage } from 'gatsby-plugin-image'
import { WrapHeader, Section } from '~/components'
import * as s from '~/components/pages/wrap/wrap-message.module.scss'

export function WrapMessage() {
  const checkoutHandle = () => {
    if (typeof window === 'undefined') return
    window.location.href = '/wrap/order'
  }
  return (
    <Section>
      <WrapHeader title="MESSAGE" lead="靴職人紹介" />
      <div className={s.message_wrapper}>
        <StaticImage
          src="../../../images/wrap/wrap_message.jpg"
          alt="message"
          placeholder="blurred"
          layout="fullWidth"
          objectFit="contain"
          className={s.message_img}
        />
        <div className={s.message_container}>
          <h4>
            一足の靴を長く使うこと、
            <br className="visible-phone" />
            それが地球を守ること。
          </h4>
          <div className={s.message_content}>
            <p>
              どれだけ大切なスニーカーも10年経つと加水分解してしまう可能性が高く、思い出のある大切な靴を少しでも長く楽しむために、このスニーカーラップは大切だと思います。
              <br />
              そして、スニーカー素材の大半が自然に戻りにくい場合が多く、環境負荷が強いこと。
              <br />
              職人の技術を活かしながら「お客様の大切なスニーカーを守りたい」
              <br />
              そんな想いでまごころいっぱい、このサービスを展開しています。
            </p>
            <p>岸田 将志（キシダ ショウジ）</p>
          </div>
        </div>
      </div>
      <div
        id="wrap-message-shopify"
        className="shopify-buy-frame shopify-buy-frame--productSet"
      >
        <div className="shopify-buy__collection-products shopify-buy-frame shopify-buy-frame--product shopify-buy-frame shopify-buy-frame--product shopify-buy-frame shopify-buy-frame--product">
          <div
            className={
              s.wrap_shopify_layout + ' has-image shopify-buy__layout-vertical'
            }
          >
            <div className="shopify-buy__btn-and-quantity">
              <div className="shopify-buy__btn-wrapper">
                <button
                  className="shopify-buy__btn shopify-buy__beside-quantity"
                  onClick={checkoutHandle}
                >
                  注文する
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}
