import { Link } from 'gatsby'
import parse from 'html-react-parser'
import Logo from './common_logo_bk.svg'
import Account from './common_icon_account.svg'
import * as s from './nav.module.scss'
import { OrderFixedNav, SnsFollow } from '~/components'

export function Header({
  siteTitle,
  isHomePage,
  isSpecialPage,
  isWrapOrderPage,
}) {
  const gnavClose = e => {
    if (document.getElementById('smt-menu-css').checked) {
      document.getElementById('smt-menu-css').checked = false
    }
  }

  return (
    <>
      {isWrapOrderPage ? (
        <> </>
      ) : isSpecialPage ? (
        <OrderFixedNav />
      ) : (
        <header id="header" className={s.header}>
          <div className={s.headerLogo + ' headerLogo'}>
            {isHomePage ? (
              <h1>
                <Link to="/">
                  <div role="img" aria-label={parse(siteTitle)}>
                    <Logo />
                  </div>
                </Link>
              </h1>
            ) : (
              <Link to="/">
                <div role="img" aria-label={parse(siteTitle)}>
                  <Logo />
                </div>
              </Link>
            )}
          </div>
          <input
            type="checkbox"
            id="smt-menu-css"
            className={s.onoff + ' hidden'}
          />
          <label
            id="smtGnaviBtn"
            htmlFor="smt-menu-css"
            className={s.smtGnaviBtn + ' visible-tablet'}
          >
            <span className={s.menuicon + ' menuicon'}>
              <span className={s.top}></span>
              <span className={s.middle}></span>
              <span className={s.bottom}></span>
            </span>
          </label>
          <nav className={s.gnav}>
            <ul className={s.gnavMain + ' gnavMain heading english'}>
              <li>
                <Link to="/#topPlan" onClick={gnavClose}>
                  <span className="visible-tablet">料金プラン</span>
                  <span>Plan</span>
                </Link>
              </li>
              <li>
                <Link to="/blog/" onClick={gnavClose}>
                  <span className="visible-tablet">スニッシュ マガジン</span>
                  <span>SNISH Magagine</span>
                </Link>
              </li>
              <li>
                <Link to="/pages/faq/" onClick={gnavClose}>
                  <span className="visible-tablet">よくあるご質問</span>
                  <span>FAQ</span>
                </Link>
              </li>
              <li>
                <Link to="/contact/" onClick={gnavClose}>
                  <span className="visible-tablet">お問い合わせ</span>
                  <span>Contact</span>
                </Link>
              </li>
              <li className={s.gnavAccount}>
                <a
                  onClick={gnavClose}
                  aria-label="account"
                  href="https://shpree-snish.myshopify.com/account/"
                >
                  <span>
                    <Account />
                  </span>
                </a>
              </li>
              <li className={s.gnavBack}>
                <Link to="/" onClick={gnavClose}>
                  <span>Top</span>
                </Link>
              </li>
              <li className={s.gnavRequest}>
                <Link to="/order/" onClick={gnavClose}>
                  <span>お申し込み</span>
                </Link>
              </li>
            </ul>
            <div className={s.gnavFooter}>
              <div className="visible-tablet">
                <ul className={s.gnavSub}>
                  <SnsFollow />
                </ul>
              </div>
            </div>
          </nav>
          <nav className={s.accountNav + ' visible-tablet'}>
            <ul>
              <li className={s.gnavAccount}>
                <a
                  href="https://shpree-snish.myshopify.com/account/"
                  aria-label="account"
                  onClick={gnavClose}
                >
                  <span>
                    <Account />
                  </span>
                </a>
              </li>
            </ul>
          </nav>
        </header>
      )}
    </>
  )
}
