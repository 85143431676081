import { StaticImage } from 'gatsby-plugin-image'
import { WrapHeader, Section } from '~/components'
import * as s from '~/components/pages/top/top.module.scss'
import Data from '~/json/wrap-flow.json'

export function WrapFlow() {
  return (
    <Section color="gray" className={s.flowSection}>
      <WrapHeader title="FLOW" lead="ご利用の流れ" />
      <div className="columnComponent is-2column">
        {Data.flow.map(({ heading, text }, index) => (
          <section className="columnChild" key={heading}>
            <div
              className={s.topImgWrap + ' imgWrapComponent with-border heading'}
            >
              <FlowThumbnail index={index} />
            </div>
            <h4 className={s.topHeading + ' heading is-primary'}>{heading}</h4>
            <p>{text}</p>
          </section>
        ))}
      </div>
    </Section>
  )
}

function FlowThumbnail({ index }) {
  /**
   * this redundant code is for the sake of Gatsby image specification
   */

  const image0 = '../images/top_flow_image_1.jpg'
  const image1 = '../images/top_flow_image_2.jpg'
  const image2 = '../images/top_flow_image_3.jpg'
  const image3 = '../images/top_flow_image_4.jpg'

  return index === 0 ? (
    <StaticImage
      src={image0}
      alt="flow image"
      placeholder="blurred"
      layout="fullWidth"
    />
  ) : index === 1 ? (
    <StaticImage
      src={image1}
      alt="flow image"
      placeholder="blurred"
      layout="fullWidth"
    />
  ) : index === 2 ? (
    <StaticImage
      src={image2}
      alt="flow image"
      placeholder="blurred"
      layout="fullWidth"
    />
  ) : (
    <StaticImage
      src={image3}
      alt="flow image"
      placeholder="blurred"
      layout="fullWidth"
    />
  )
}
