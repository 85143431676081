// extracted by mini-css-extract-plugin
export var example_img = "sole-merit-module--example_img--zB1vI";
export var fadeIn = "sole-merit-module--fadeIn--hfloL";
export var merit_columns = "sole-merit-module--merit_columns--00oPO";
export var merit_container = "sole-merit-module--merit_container--qS4Rv";
export var merit_technology = "sole-merit-module--merit_technology--4CRwk";
export var merit_technology_desc = "sole-merit-module--merit_technology_desc--JXwYV";
export var merit_technology_icon_circle = "sole-merit-module--merit_technology_icon_circle--61usa";
export var merit_technology_icon_cross = "sole-merit-module--merit_technology_icon_cross--ssb6i";
export var merit_technology_image = "sole-merit-module--merit_technology_image--cRrqZ";
export var merit_technology_inner = "sole-merit-module--merit_technology_inner--Yz7LE";
export var merit_technology_use = "sole-merit-module--merit_technology_use--IbdCL";
export var merit_technology_wrap = "sole-merit-module--merit_technology_wrap--oHhnd";
export var merit_text_container = "sole-merit-module--merit_text_container--c2AoJ";
export var merit_wrapper = "sole-merit-module--merit_wrapper--6Qhu4";