import { useState } from 'react';
import Modal from 'react-modal'
import Icon from '~/images/common_icon_help.svg'
import CloseIcon from '~/images/common_icon_close.svg'
import * as s from '~/components/pages/order/order.module.scss'

Modal.setAppElement('#___gatsby')

export function MenuModal() {
  const [modalIsOpen, setIsOpen] = useState(false)
  const handleCloseModal = () => {
    setIsOpen(false)
  }

  const modalStyle = {
    overlay: {
      zIndex: '99999',
      backgroundColor: 'rgba(16, 16, 16, 0.3)',
    },
    content: {
      padding: 0,
      zIndex: '99999',
      border: 0,
      borderRadius: 0,
      backgroundColor: '#FAFAFA',
    },
  }

  return (
    <>
      <button
        type="button"
        onClick={() => setIsOpen(true)}
        className={s.orderModalSwitch}
      >
        <span>
          <Icon />
          メニュー説明
        </span>
      </button>

      <Modal
        style={modalStyle}
        isOpen={modalIsOpen}
        onRequestClose={() => handleCloseModal()}
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={true}
      >
        <div className="modalWrap">
          <section className={s.orderMenuContent}>
            <button
              onClick={() => setIsOpen(false)}
              className={s.orderModalClose}
            >
              <CloseIcon />
            </button>
            <h2 className="heading is-primary">メニュー説明</h2>
            <dl className={s.orderMenuDl}>
              <div>
                <dt className="heading is-secondary">かかと修理</dt>
                <dd>
                  スニーカーのかかとの擦り減りや、かかと全体の修理・交換。かかとに合うお色を選んで修理させていただきます。
                </dd>
              </div>
              <div>
                <dt className="heading is-secondary">
                  靴底全体の修理/交換（オールソール）
                </dt>
                <dd>
                  靴底全体の修理・交換。ソールに合うお色を選んで修理させていただきます。
                </dd>
              </div>
              <div>
                <dt className="heading is-secondary">
                  スニーカーウォッシュ
                </dt>
                <dd>
                  お客様それぞれの靴の状態に合わせ、職人が一足一足丁寧に磨き上げます。環境にも人にも優しい最高級靴クリームを使用し、靴の素材や色に合わせたメンテナンスを行っています。
                </dd>
              </div>
              <div>
                <dt className="heading is-secondary">
                  ウイルス殺菌・抗菌・脱臭
                </dt>
                <dd>
                  靴専用の殺菌・脱臭機で、靴を傷めることなく行っています。
                </dd>
              </div>
            </dl>
          </section>
        </div>
      </Modal>
    </>
  )
}
