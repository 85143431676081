import * as s from '~/components/pages/top/top.module.scss'
import ReactCompareImage from 'react-compare-image'
import BEFORE_WASH from '~/images/before-after/top_before_wash.jpg'
import AFTER_WASH from '~/images/before-after/top_after_wash.jpg'
import BEFORE_REPAIR from '~/images/before-after/top_before_repair.jpg'
import AFTER_REPAIR from '~/images/before-after/top_after_repair.jpg'

export function BeforeAfter() {
  return (
    <>
      <header>
        <h3
          className={
            s.topSectionTitle + ' contentComponent heading with-english'
          }
        >
          <span>お客さま事例</span>
          <div className="hidden-phone">BEFORE / AFTER</div>
          <div className="visible-phone">
            BEFORE
            <br />- AFTER
          </div>
        </h3>
      </header>
      <div className="contentComponent columnComponent is-2column">
        <section className="columnChild">
          <div
            id="imgCompareWash"
            className={s.topImgWrap + ' imgWrapComponent with-border'}
          >
            <ReactCompareImage
              leftImage={BEFORE_WASH}
              rightImage={AFTER_WASH}
            />
          </div>
          <h4 className={s.topHeading + ' heading is-primary'}>
            スニーカーウォッシュ
          </h4>
        </section>
        <section className="columnChild">
          <div className={s.topImgWrap + ' imgWrapComponent with-border'}>
            <ReactCompareImage
              leftImage={BEFORE_REPAIR}
              rightImage={AFTER_REPAIR}
            />
          </div>
          <h4 className={s.topHeading + ' heading is-primary'}>修理</h4>
        </section>
      </div>
    </>
  )
}
