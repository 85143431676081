// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-contact-deliver-jsx": () => import("./../../../src/pages/contact-deliver.jsx" /* webpackChunkName: "component---src-pages-contact-deliver-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-contact-order-jsx": () => import("./../../../src/pages/contact-order.jsx" /* webpackChunkName: "component---src-pages-contact-order-jsx" */),
  "component---src-pages-contact-other-jsx": () => import("./../../../src/pages/contact-other.jsx" /* webpackChunkName: "component---src-pages-contact-other-jsx" */),
  "component---src-pages-contact-repair-jsx": () => import("./../../../src/pages/contact-repair.jsx" /* webpackChunkName: "component---src-pages-contact-repair-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-order-jsx": () => import("./../../../src/pages/order.jsx" /* webpackChunkName: "component---src-pages-order-jsx" */),
  "component---src-pages-sole-index-jsx": () => import("./../../../src/pages/sole/index.jsx" /* webpackChunkName: "component---src-pages-sole-index-jsx" */),
  "component---src-pages-sole-order-jsx": () => import("./../../../src/pages/sole/order.jsx" /* webpackChunkName: "component---src-pages-sole-order-jsx" */),
  "component---src-pages-wrap-index-jsx": () => import("./../../../src/pages/wrap/index.jsx" /* webpackChunkName: "component---src-pages-wrap-index-jsx" */),
  "component---src-pages-wrap-order-jsx": () => import("./../../../src/pages/wrap/order.jsx" /* webpackChunkName: "component---src-pages-wrap-order-jsx" */),
  "component---src-templates-blog-category-archive-jsx": () => import("./../../../src/templates/blog-category-archive.jsx" /* webpackChunkName: "component---src-templates-blog-category-archive-jsx" */),
  "component---src-templates-blog-page-jsx": () => import("./../../../src/templates/blog-page.jsx" /* webpackChunkName: "component---src-templates-blog-page-jsx" */),
  "component---src-templates-blog-post-archive-jsx": () => import("./../../../src/templates/blog-post-archive.jsx" /* webpackChunkName: "component---src-templates-blog-post-archive-jsx" */),
  "component---src-templates-blog-post-jsx": () => import("./../../../src/templates/blog-post.jsx" /* webpackChunkName: "component---src-templates-blog-post-jsx" */),
  "component---src-templates-blog-tag-archive-jsx": () => import("./../../../src/templates/blog-tag-archive.jsx" /* webpackChunkName: "component---src-templates-blog-tag-archive-jsx" */)
}

