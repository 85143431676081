export function ShopifyBuyInit({ buyId, elementId, type, caption = true }) {
  const client = ShopifyBuy.buildClient({
    domain: 'shpree-snish.myshopify.com',
    storefrontAccessToken: 'bd6fcd58441343b7e495adb948fb7ebf',
  })
  ShopifyBuy.UI.onReady(client).then(function (ui) {
    ui.createComponent(type, {
      id: buyId,
      node: document.getElementById(elementId),
      moneyFormat: '%C2%A5%7B%7Bamount_no_decimals%7D%7D',
      options: {
        product: {
          iframe: false,
          styles: {},
          contents: {
            img: false,
            button: false,
            buttonWithQuantity: true,
            title: caption,
            price: caption,
          },
          text: {
            button: 'カートに入れる',
          },
        },
        productSet: {
          iframe: false,
        },
        cart: {
          iframe: false,
          text: {
            total: '合計',
            button: 'レジへ進む',
            notice: '送料はこの後のステップで自動計算されます。2足以上でメニューが異なる場合は、注文メモ欄でご説明をお願いします。',
            noteDescription: '注文メモ',
          },
          contents: {
            note: true,
          },
          popup: false,
        },
        lineItem: {
          contents: {
            image: false,
          },
        },
        toggle: {
          iframe: false,
        },
      },
    })
  })
}
