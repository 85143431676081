import { useState } from 'react'

export function Subscription({ item }) {
  const { title, linkUrl, text, price } = item
  const [quantity, setQuantity] = useState(1)

  const handleInputChange = e => {
    const target = e.target
    const value = target.value

    setQuantity(value)
  }

  const checkoutHandle = () => {
    window.open(`${linkUrl}&quantity=${quantity}`, '_blank')
  }

  return (
    <section className="acordionWrapper for-subscription">
      <header className="acordionSwitch">
        <h2 className="heading is-secondary">{title}</h2>
        <div
          dangerouslySetInnerHTML={{ __html: text }}
          className="subscription-text"
        />
      </header>
      <div className="acordionContent shopify-buy-frame shopify-buy-frame--productSet">
        <div className="shopify-buy__collection">
          <div className="shopify-buy__collection-products shopify-buy-frame shopify-buy-frame--product shopify-buy-frame shopify-buy-frame--product shopify-buy-frame shopify-buy-frame--product">
            <div className="has-image shopify-buy__layout-vertical shopify-buy__product">
              <h1 className="shopify-buy__product__title">{title}</h1>
              <div className="shopify-buy__product__price">
                <span className="shopify-buy__product__actual-price ">
                  {price}
                </span>
              </div>
              <div className="shopify-buy__btn-and-quantity">
                <div className="shopify-buy__quantity-container ">
                  <input
                    className="shopify-buy__quantity"
                    type="number"
                    min="1"
                    aria-label="Quantity"
                    value={quantity}
                    data-element="1"
                    onChange={handleInputChange}
                  />
                </div>
                <div className="shopify-buy__btn-wrapper">
                  <button
                    className="shopify-buy__btn shopify-buy__beside-quantity"
                    onClick={checkoutHandle}
                  >
                    この内容で注文
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
