import { StaticImage } from 'gatsby-plugin-image'
import { WrapHeader, Section } from '~/components'
import { topHeading } from '~/components/pages/top/top.module.scss'
import * as s from '~/components/pages/wrap/wrap-merit.module.scss'
import Data from '~/json/wrap-merits.json'

export function WrapMerit() {
  return (
    <Section className={s.merit_container}>
      <div className={s.merit_text_container}>
        <h2 className="text-accent">
          スニーカーのこんなお悩み
          <br className="visible-phone" />
          ありませんか？
        </h2>
        <ul>
          <li>大切なスニーカーを長持ちできるように保管したい</li>
          <li>自分でやるけどうまくラップできない</li>
          <li>職人に綺麗にラップしてほしい</li>
        </ul>
      </div>
      <div className={s.merit_wrapper}>
        <WrapHeader title="MERIT" lead="職人の手によるメリットとは？" />
        <div className={s.merit_columns + ' columnComponent is-2column'}>
          {Data.merits.map(({ heading, text }, index) => (
            <section className="columnChild" key={heading}>
              <div
                className={
                  s.example_img + ' imgWrapComponent with-border heading'
                }
              >
                {index === 0 ? (
                  <StaticImage
                    src="../../../images/wrap/merit01.jpg"
                    alt={heading}
                    placeholder="blurred"
                    layout="fullWidth"
                  />
                ) : index === 1 ? (
                  <StaticImage
                    src="../../../images/wrap/merit02.jpg"
                    alt={heading}
                    placeholder="blurred"
                    layout="fullWidth"
                  />
                ) : index === 2 ? (
                  <StaticImage
                    src="../../../images/wrap/merit03.jpg"
                    alt={heading}
                    placeholder="blurred"
                    layout="fullWidth"
                  />
                ) : (
                  <StaticImage
                    src="../../../images/wrap/merit04.jpg"
                    alt={heading}
                    placeholder="blurred"
                    layout="fullWidth"
                  />
                )}
              </div>
              <h4 className={topHeading + ' heading is-primary text-accent'}>
                {heading}
              </h4>
              <p>{text}</p>
            </section>
          ))}
        </div>
      </div>
    </Section>
  )
}
