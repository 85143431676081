import { StaticImage } from 'gatsby-plugin-image'
import { Section } from '~/components'
import * as s from '~/components/pages/sole/sole-first-view.module.scss'
import Logo from '../../../images/mv_logo_sole_bk.svg'
import { motion } from 'framer-motion'
import { useWindowSize } from 'react-use'
import { WINDOW_THRESHOLD } from '../../../lib/const'

const EASING = [0, 0.71, 0.2, 1.01]

function MotionText() {
  const textVariants = {
    initial: { opacity: 0, y: 30 },
    animate: { opacity: 1, y: 0 },
    transition: {
      duration: 0.6,
      ease: EASING,
    },
  }

  return (
    <>
      <div className={s.fv_header_wrap}>
        <motion.h2
          variants={textVariants}
          initial="initial"
          animate="animate"
          transition={{ ...textVariants.transition, delay: 0.4 }}
          className={s.fv_header}
        >
          愛着あるスニーカーを長く履きたい
        </motion.h2>
      </div>
    </>
  )
}

export function SoleFirstView() {
  const isTablet = useWindowSize().width < WINDOW_THRESHOLD.xl

  const imageVariantsBefore = {
    initial: isTablet
      ? { opacity: 0, x: -30, y: -80 }
      : { opacity: 0, x: -30, y: 80 },
    animate: { opacity: 1, x: 0, y: 0 },
    transition: {
      duration: 2.0,
      ease: EASING,
    },
  }

  const imageVariantsAfter = {
    initial: { opacity: 0, x: 30, y: 80 },
    animate: { opacity: 1, x: 0, y: 0 },
    transition: {
      duration: 2.0,
      ease: EASING,
    },
  }

  const rightContainerVariants = {
    initial: {
      clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)',
    },
    animate: {
      clipPath: isTablet
        ? 'polygon(0% 25%, 100% 0, 100% 100%, 0% 100%)'
        : 'polygon(33% 0%, 100% 0%, 100% 100%, 0% 100%)',
    },
    transition: {
      duration: 1.4,
      ease: EASING,
    },
  }

  return (
    <Section
      color="none"
      padding="none"
      width="full"
      className={s.fv_container}
    >
      <div id="mainVisual" className={s.mainVisual}>
        <MotionText />
        <div className={s.main_visual_before}>
          <motion.span
            variants={imageVariantsBefore}
            initial="initial"
            animate="animate"
            transition={{ ...imageVariantsBefore.transition, delay: 0.8 }}
            className={s.main_visual_img_before}
          >
            <StaticImage
              src="../../../images/sole/main-before.png"
              alt="first view"
              objectFit="contain"
              className={s.main_visual_img}
            />
          </motion.span>
        </div>
        <div className={s.main_visual_after}>
          <motion.span
            variants={imageVariantsAfter}
            initial="initial"
            animate="animate"
            transition={{ ...imageVariantsAfter.transition, delay: 0.8 }}
            className={s.main_visual_img_after}
          >
            <StaticImage
              src="../../../images/sole/main-after.png"
              alt="first view"
              objectFit="contain"
              className={s.main_visual_img}
            />
          </motion.span>
        </div>
        <div className={s.left_container}></div>
        <motion.div
          variants={rightContainerVariants}
          initial="initial"
          animate="animate"
          transition={{ ...rightContainerVariants.transition, delay: 0.2 }}
          className={s.right_container}
        ></motion.div>
        <div className={s.mainVisualTitle}>
          <p>
            職人の手による、デザインを活かした
            <br className="visible-phone" />
            靴底全体のソール補強
          </p>
          <div role="img" aria-label="SNISH">
            <Logo />
          </div>
        </div>
      </div>
    </Section>
  )
}
