import GoTopSvg from '~/images/go_top.svg'
import * as s from '~/components/nav/nav.module.scss'

export function GoTop() {
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  return (
    <div className={s.go_top}>
      <button onClick={handleScrollToTop}>
        <GoTopSvg />
      </button>
    </div>
  )
}
