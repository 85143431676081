import { WrapHeader, Section, Faq } from '~/components'
import Questions from '~/json/faq.json'

export function WrapFaq() {
  const faq = Questions.wrap_faq

  return (
    <Section color="gray">
      <WrapHeader title="FAQ" lead="よくある質問" />
      <Faq faq={faq} />
    </Section>
  )
}
