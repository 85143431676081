import { useEffect, useState } from 'react'
import { Link } from 'gatsby'
import Logo from './common_logo_wh.svg'
import * as s from './nav.module.scss'
import { fadeIn } from '~/components/pages/wrap/wrap.module.scss'

export function OrderFixedNav() {
  const checkoutHandle = () => {
    if (typeof window === 'undefined') return
    const currentURL = window.location.href
    const firstLevel = currentURL.split('/')[3]
    if (firstLevel === 'wrap') {
      window.location.href = '/wrap/order'
    } else if (firstLevel === 'sole') {
      window.location.href = '/sole/order'
    }
    return
  }

  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    setLoaded(true)
  }, [])

  return (
    <>
      <header className={`${s.order_fixed_nav} ${loaded ? fadeIn : ''}`}>
        <div className={s.headerLogo + ' headerLogo'}>
          <Link to="./">
            <Logo />
          </Link>
        </div>

        <div className="special_page_shopify shopify-buy-frame shopify-buy-frame--productSet">
          <div className="shopify-buy__collection-products shopify-buy-frame shopify-buy-frame--product shopify-buy-frame shopify-buy-frame--product shopify-buy-frame shopify-buy-frame--product">
            <div className="has-image shopify-buy__layout-vertical">
              <div className="shopify-buy__btn-and-quantity">
                <div className="shopify-buy__btn-wrapper">
                  <button
                    className="shopify-buy__btn shopify-buy__beside-quantity"
                    onClick={checkoutHandle}
                  >
                    注文する
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  )
}
