import './src/styles/normalize.css'
import './src/styles/container.scss'

function acordionToggle() {
  const toggleInner = document.querySelectorAll('.acordionSwitch')
  let nextdd,
    nextddHeight,
    nextddH = []

  let toggleLoop = i => {
    nextddH[i] = toggleInner[i].nextElementSibling.clientHeight
    toggleInner[i].nextElementSibling.style.height = '0px'

    toggleInner[i].addEventListener(
      'click',
      () => {
        toggleInner[i].classList.toggle('is-open')
        nextdd = toggleInner[i].nextElementSibling
        nextddHeight = nextdd.firstElementChild.clientHeight

        if (nextdd.style.height !== '0px') {
          nextdd.style.height = '0px'
        } else {
          nextdd.style.height = nextddHeight + 'px'
        }
      },
      false
    )
  }

  for (let i = 0; i < toggleInner.length; i++) {
    toggleLoop(i)
  }
}

function StickyMenu(location) {
  const header = document.querySelector('#header')

  if (!header) return

  if (location.pathname === '/') {
    document.querySelector('body').classList.add('home')
    header.classList.add('top_page_header')
    header.classList.add('bottomFixed')

    // change SP header position
    // if it's on bottom, it will be fixed on top when the menu is opened
    let gnavButton = document.querySelector('#smtGnaviBtn')

    gnavButton.addEventListener('click', function () {
      if (header.classList.contains('is-open')) {
        header.classList.remove('is-open')

        if (!header.classList.contains('bottomFixed')) {
          header.classList.add('bottomFixed')
        }
      } else {
        header.classList.add('is-open')
        header.classList.remove('bottomFixed')
      }
    })
  } else if (
    location.pathname === '/wrap' ||
    location.pathname === '/wrap/' ||
    location.pathname === '/sole' ||
    location.pathname === '/sole/'
  ) {
    document.querySelector('body').classList.add('home')
  } else {
    document.querySelector('body').classList.remove('home')
    header.classList.remove('top_page_header')
    header.classList.remove('bottomFixed')
  }
}

function visibleVideoPlayButton() {
  const video = document.querySelectorAll('.video')
  video.forEach(function (el) {
    const thumbnail = el.querySelector('.video-thumbnail')
    const playButton = el.querySelector('.video-play')

    const handleVideoPlay = () => {
      el.classList.add('played')
      el.querySelector('video').play()
    }

    thumbnail.addEventListener('click', function () {
      handleVideoPlay()
    })
    playButton.addEventListener('click', function () {
      handleVideoPlay()
    })
  })
}

export function onRouteUpdate({ location }) {
  acordionToggle()
  StickyMenu(location)
  visibleVideoPlayButton()
}
