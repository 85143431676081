// extracted by mini-css-extract-plugin
export var article = "blog-module--article--SFdzB";
export var articleAuthor = "blog-module--articleAuthor--SU11W";
export var articleBackLink = "blog-module--articleBackLink--zdqdr";
export var articleBody = "blog-module--articleBody--LFqUo";
export var articleCategory = "blog-module--articleCategory--ItXPz";
export var articleDate = "blog-module--articleDate--jbQ45";
export var articleDetail = "blog-module--articleDetail--YIg3U";
export var articleExpert = "blog-module--articleExpert--qyNb2";
export var articleFooter = "blog-module--articleFooter--s4R4I";
export var articleHeader = "blog-module--articleHeader--CLW2l";
export var articleHeaderDetail = "blog-module--articleHeaderDetail--MuIgF";
export var articleItem = "blog-module--articleItem--4Y92F";
export var articleLatestList = "blog-module--articleLatestList--sNmn8";
export var articleList = "blog-module--articleList--TqMm9";
export var articleRelatedList = "blog-module--articleRelatedList--xKT3C";
export var articleShare = "blog-module--articleShare--1GUhc";
export var articleTags = "blog-module--articleTags--88-Ub";
export var articleTitle = "blog-module--articleTitle--WBEJm";
export var currentCategory = "blog-module--currentCategory--sLk5g";
export var fadeIn = "blog-module--fadeIn--w1Pwv";
export var relatedPostList = "blog-module--relatedPostList--j+8mf";
export var thumbnail = "blog-module--thumbnail--7fqX1";