import { StaticImage } from 'gatsby-plugin-image'
import { WrapHeader, Section } from '~/components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'
import * as s from '~/components/pages/wrap/wrap-voice.module.scss'

export function WrapVoice() {
  return (
    <Section>
      <WrapHeader title="USER'S VOICE" lead="お客様の声" />
      <div className={s.voice_wrapper}>
        <div className={s.voice_container}>
          <div className={s.voice_inner}>
            <h4>
              スニーカーの顔を意識したラップ。綺麗な仕上がりに満足しています。
            </h4>
            <p>
              ラップ前に靴専用の殺菌機で、カビの元などを殺菌。スニーカー内の見えない水分（加水分解の元）を乾燥させ、ラップをアッパー（フロント、サイド）に切れ目がこないようにラップ。
              ⁡ 綺麗にラッピングして頂き、ありがとうございました。
            </p>
            <a
              href="https://www.instagram.com/hypebeast_offf/"
              target="_blank"
              rel="noopener noreferrer"
              className={s.voice_instagram}
            >
              <StaticImage
                src="../../../images/wrap/yusuke.jpg"
                alt="voice"
                placeholder="blurred"
                layout="fixed"
                width={64}
                height={64}
              />
              <div className="font-en">
                <h6>Yusuke</h6>
                <p>
                  <FontAwesomeIcon icon={faInstagram} />
                  <span>hypebeast_offf</span>
                </p>
              </div>
            </a>
          </div>
          <StaticImage
            src="../../../images/wrap/voice01.jpg"
            alt="voice"
            placeholder="blurred"
            layout="fullWidth"
            objectFit="contain"
            className={s.voice_img}
          />
        </div>

        <div className={s.voice_container}>
          <div className={s.voice_inner}>
            <h4>
              自分で今までやっていましたが、上手くできずプロにお願いして良かった。
            </h4>
            <p>
              自分でもラップしていましたがトゥやヒール部分が上手くできず、プロにお願い。過度に密着しすぎずスニーカーのカタチを崩さずに、めちゃくちゃ良い感じに仕上げてもらいました。ありがとうございました。
            </p>
            <div className={s.voice_instagram}>
              <h6>Zushi</h6>
            </div>
          </div>
          <StaticImage
            src="../../../images/wrap/voice02.jpg"
            alt="voice"
            placeholder="blurred"
            layout="fullWidth"
            objectFit="contain"
            className={s.voice_img}
          />
        </div>
      </div>
    </Section>
  )
}
