// extracted by mini-css-extract-plugin
export var description = "sole-plan-module--description--IQihP";
export var detail = "sole-plan-module--detail--jg8IE";
export var fadeIn = "sole-plan-module--fadeIn--26wYh";
export var plan_check_list = "sole-plan-module--plan_check_list--EZKJt";
export var plan_check_supplement = "sole-plan-module--plan_check_supplement--q2JUy";
export var plan_content = "sole-plan-module--plan_content--iAHKi";
export var plan_image = "sole-plan-module--plan_image--DEX-0";
export var plan_item = "sole-plan-module--plan_item--C7hCa";
export var plan_list = "sole-plan-module--plan_list--hiAhm";
export var plan_orderable = "sole-plan-module--plan_orderable--8xiGp";
export var plan_slider = "sole-plan-module--plan_slider--M3ad1";
export var plan_title = "sole-plan-module--plan_title--5xPf-";
export var plan_title_s = "sole-plan-module--plan_title_s--SLI-z";
export var price = "sole-plan-module--price--1GueU";
export var sole_shopify_layout = "sole-plan-module--sole_shopify_layout--rQNEK";