import clsx from 'clsx'

export function Section({
  as: Component = 'section',
  children,
  className,
  color = 'default',
  padding = 'default',
  width = 'default',
  ...props
}) {
  const bg = {
    default: 'bg-white',
    gray: 'bg-gray',
    none: '',
  }

  const paddings = {
    default: 'py-default',
    none: '',
  }

  const widths = {
    default: 'contentComponent',
    full: '',
  }

  const styles = clsx(bg[color], paddings[padding], className)

  return (
    <Component {...props} className={styles}>
      <div className={widths[width]}>{children}</div>
    </Component>
  )
}
