import {
  FacebookShareButton,
  TwitterShareButton,
  LineShareButton,
} from 'react-share'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter, faFacebookF } from '@fortawesome/free-brands-svg-icons'
import LineIcon from '~/images/common_icon_line.svg'

export function SnsShare({ title, link }) {
  return (
    <>
      <dd className="shareIconsTwitter">
        <TwitterShareButton title={title} url={link} via="snishjp">
          <FontAwesomeIcon icon={faTwitter} />
        </TwitterShareButton>
      </dd>
      <dd className="shareIconsFacebook">
        <FacebookShareButton url={link}>
          <FontAwesomeIcon icon={faFacebookF} />
        </FacebookShareButton>
      </dd>
      <dd className="shareIconsLine">
        <LineShareButton url={link}>
          <LineIcon />
        </LineShareButton>
      </dd>
    </>
  )
}
