export function Faq({ faq }) {
  return (
    <div className="acordionComponent">
      {faq.map(({ q, a }) => (
        <section className="faqAcordionWrap" key={q}>
          <h3 className="acordionSwitch heading is-secondary">{q}</h3>
          <div className="acordionContent">
            <div className="faqDetail">{a}</div>
          </div>
        </section>
      ))}
    </div>
  )
}
