import { useEffect, useState } from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { SoleHeader, Section } from '~/components'
import * as s from '~/components/pages/sole/sole-plan.module.scss'
import Slider from 'react-slick'
import Data from '~/json/sole-plans.json'

const sliderOptions = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  pauseOnHover: true,
  arrows: false,
}

function SoleSlider() {
  return (
    <Slider {...sliderOptions}>
      <StaticImage
        src="../../../images/sole/plan-slide01-01.png"
        placeholder="blurred"
        layout="fullWidth"
      />
      <StaticImage
        src="../../../images/sole/plan-slide01-02.png"
        placeholder="blurred"
        layout="fullWidth"
      />
    </Slider>
  )
}

function SoleSlider2() {
  return (
    <Slider {...sliderOptions}>
      <StaticImage
        src="../../../images/sole/plan-slide02-01.png"
        placeholder="blurred"
        layout="fullWidth"
      />
      <StaticImage
        src="../../../images/sole/plan-slide02-02.png"
        placeholder="blurred"
        layout="fullWidth"
      />
      <StaticImage
        src="../../../images/sole/plan-slide02-03.png"
        placeholder="blurred"
        layout="fullWidth"
      />
      <StaticImage
        src="../../../images/sole/plan-slide02-04.png"
        placeholder="blurred"
        layout="fullWidth"
      />
    </Slider>
  )
}

export function SolePlan() {
  const checkoutHandle = () => {
    if (typeof window === 'undefined') return
    window.location.href = '/sole/order'
  }
  const [soles, setSoles] = useState([])
  const [whiteBlackSoles, setWhiteBlackSoles] = useState([])
  const [others, setOthers] = useState([])
  useEffect(() => {
    setSoles(Data.plans.filter(item => item.type === 'sole'))
    setWhiteBlackSoles(
      Data.plans.filter(item => item.type === 'whiteBlackSole')
    )
    setOthers(Data.plans.filter(item => item.type === 'other'))
  }, [])

  const renderPlan = (plans, planTitle, sliderNumber) => {
    return plans.map(({ elementId, title, price, description, points }) => (
      <div key={elementId} className={s.plan_item}>
        <h4 className={s.plan_title}>{planTitle}</h4>
        <div className={s.plan_content}>
          <div className={s.plan_slider}>
            {sliderNumber === 1 ? <SoleSlider /> : <SoleSlider2 />}
          </div>
          <div className={s.description}>
            <h5>{title}</h5>
            <div className={s.detail}>{description}</div>
            <div className={s.price}>
              <strong className="font-en">{price}</strong>
              <span>（税込）</span>
            </div>
            <ul className={s.plan_list}>
              {points.map(({ text }) => (
                <li>{text}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    ))[0]
  }

  const renderOtherPlans = plans => {
    return plans.map(({ elementId, title, price, description }) => (
      <div key={elementId}>
        <h5>{title}</h5>
        {description ? <div className={s.detail}>{description}</div> : null}

        <div className={s.price}>
          <strong className="font-en">{price}</strong>
          <span>（税込）</span>
        </div>
      </div>
    ))
  }

  return (
    <Section>
      <SoleHeader title="PLAN" lead="補強プラン & 料金" />
      {renderPlan(soles, 'SNISH Sole', 1)}
      {renderPlan(whiteBlackSoles, 'SNISH White/Black Sole', 2)}

      <div className={s.plan_item}>
        <h4 className={s.plan_title}>Other</h4>
        <div className={s.plan_content}>
          <div>{renderOtherPlans(others)}</div>
        </div>
      </div>
      <div className={s.plan_item}>
        <h4 className={s.plan_title_s}>ご確認事項</h4>
        <div className={s.plan_content}>
          <div>
            <ul className={s.plan_check_list}>
              <li>本来のスニーカーのオリジナルソールを補強可能です。</li>
              <li>
                フラットなソールのスニーカーがオススメです。
                <br />
                オススメのソール補強モデル：NIKE
                エアジョーダン1、エアフォース（ブラックソール）、VANS、コンバースオールスター
              </li>
            </ul>
            <ul className={s.plan_check_supplement}>
              <li>
                ソールがフラットなスニーカーのみオールソール補強が対応可能。靴底がゴツゴツしていたり、AirMAXなどのソール形状は非対応。
              </li>
              <li>カテゴリ外もご相談ください</li>
            </ul>
          </div>
        </div>
      </div>
      <div className={s.plan_item}>
        <h4 className={s.plan_title_s}>ソール補強+リペア同時注文可能</h4>
        <div className={s.plan_content}>
          <div className={s.plan_image}>
            <StaticImage
              src="../../../images/sole/plan01.png"
              placeholder="blurred"
              layout="fullWidth"
            />
          </div>
          <div className={s.plan_orderable}>
            <h5>スニーカーリペア（かかと埋め立て）</h5>
            <div className={s.detail}>
              SNISH Soleでは、ソール補強と一緒にリペアも同時注文が可能です。
              履き慣れているのにかかとが削れてしまって履けない、もしくは履けなくなりそうなスニーカーも、かかとを埋め立てた後にSNISH
              Soleで補強することができます。
            </div>
          </div>
        </div>
      </div>
      <div
        id="sole-plan-shopify"
        className="shopify-buy-frame shopify-buy-frame--productSet"
      >
        <div className="shopify-buy__collection-products shopify-buy-frame shopify-buy-frame--product shopify-buy-frame shopify-buy-frame--product shopify-buy-frame shopify-buy-frame--product">
          <div
            className={
              s.sole_shopify_layout + ' has-image shopify-buy__layout-vertical'
            }
          >
            <div className="shopify-buy__btn-and-quantity">
              <div className="shopify-buy__btn-wrapper">
                <button
                  className="shopify-buy__btn shopify-buy__beside-quantity"
                  onClick={checkoutHandle}
                >
                  注文する
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}
