import * as s from '~/components/pages/wrap/wrap-header.module.scss'
import { domAnimation, m, LazyMotion } from 'framer-motion'

export function WrapHeader({ title, lead }) {
  const leadVariants = {
    initial: { clipPath: 'polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%)' },
    whileInView: { clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0 100%)' },
  }

  return (
    <header id="header" className="text-accent">
      <p className={s.wrap_lead}>
        <LazyMotion features={domAnimation}>
          <m.span
            variants={leadVariants}
            initial="initial"
            whileInView="whileInView"
            transition={{
              duration: 0.3,
              ease: 'easeInOut',
              delay: 0.6,
            }}
            viewport={{ once: true }}
            className={s.text_slush}
          >
            {lead}
          </m.span>
        </LazyMotion>
      </p>
      <h3 className={s.header_title + ' heading with-english uppercase'}>
        {title}
      </h3>
    </header>
  )
}
