import { StaticImage } from 'gatsby-plugin-image'
import { SoleHeader, Section } from '~/components'
import * as s from '~/components/pages/sole/sole-message.module.scss'

export function SoleMessage() {
  return (
    <Section color="gray">
      <SoleHeader title="MESSAGE" lead="靴職人紹介" isTitleWhite="true" />
      <div className={s.message_wrapper}>
        <StaticImage
          src="../../../images/wrap/wrap_message.jpg"
          alt="message"
          placeholder="blurred"
          layout="fullWidth"
          objectFit="contain"
          className={s.message_img}
        />
        <div className={s.message_container}>
          <h4>
            一足の靴を長く使うこと、
            <br className="visible-phone" />
            それが地球を守ること。
          </h4>
          <div className={s.message_content}>
            <p>
              どれだけ大切なスニーカーも10年経つと加水分解してしまう可能性が高く、思い出のある大切な靴を少しでも長く愛用できるために、このスニーカー加工は大切だと思います。
              <br />
              そして、スニーカー素材の大半が自然に戻りにくい場合が多く、環境負荷が強いこと。
              <br />
              職人の技術を活かしながら「お客様の大切なスニーカーを愛用してほしい」
              <br />
              そんな想いでまごころいっぱい、このサービスを展開しています。
            </p>
            <p>岸田 将志（キシダ ショウジ）</p>
          </div>
        </div>
      </div>
    </Section>
  )
}
