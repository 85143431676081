import { Link } from 'gatsby'
import * as s from '~/components/pages/blog/blog.module.scss'

export function TagList({ type, tags }) {
  return (
    <ul className={s.articleTags + ' mincho'}>
      {tags.map(({ uri, slug, name }) => (
        <li key={uri}>
          <Link to={`/${type}/${slug}`}>#{name}</Link>
        </li>
      ))}
    </ul>
  )
}
