import { StaticImage } from 'gatsby-plugin-image'
import { SoleHeader, Section } from '~/components'
import * as s from '~/components/pages/sole/sole-voice.module.scss'

export function SoleVoice() {
  return (
    <Section>
      <SoleHeader title="USER'S VOICE" lead="お客様の声" />
      <div className={s.voice_wrapper}>
        <div className={s.voice_container}>
          <div className={s.voice_inner}>
            <h4>
              職人さんの手による靴底全体のソール補強は強度も厚みもバッチリ！
            </h4>
            <p className={s.plan}>プラン：SNISH Sole , カラー：ボルドー</p>
            <p>
              カカトだけの補強ソールは何度か試して、お気に入りのスニーカーを履いていましたが、ずっと前側がすり減っていくのが気になっていて……SNISHさんの存在を知り、職人さんに靴底全体を貼ってもらえて、強度もバッチリです。そして、厚みが薄いか心配でしたが、耐久性のあるイタリアVibramソールなので安心してガシガシ履いています。すり減ったら、またお願いします！
            </p>
          </div>
          <StaticImage
            src="../../../images/sole/voice01.png"
            alt="voice"
            placeholder="blurred"
            layout="fullWidth"
            objectFit="contain"
            className={s.voice_img}
          />
        </div>

        <div className={s.voice_container}>
          <div className={s.voice_inner}>
            <h4>補強ソールが厚過ぎないのでスニーカーのデザインが活きてくる</h4>
            <p className={s.plan}>
              プラン：SNISH White/Black Sole , カラー：ブラック
            </p>
            <p>
              ヒールガードパーツを買って自分で貼っていましたが、どうしても強度が弱くて剥がれてしまったり少しはみ出してイマイチだったり……こちらはソールの厚みが薄いけど、丈夫なビブラムソールを使ってソールを張ってくれているとのことでお願いしましたが、サイドから見た際の違和感も全くなくデザインを活かしたまま履けるのでお願いしてよかったです。
            </p>
          </div>
          <StaticImage
            src="../../../images/sole/voice02.png"
            alt="voice"
            placeholder="blurred"
            layout="fullWidth"
            objectFit="contain"
            className={s.voice_img}
          />
        </div>
      </div>
    </Section>
  )
}
