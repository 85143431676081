import { StaticImage } from 'gatsby-plugin-image'
import { SoleHeader, Section } from '~/components'
import * as s from '~/components/pages/top/top.module.scss'
import Data from '~/json/sole-flow.json'

export function SoleFlow() {
  return (
    <Section color="gray" className={s.flowSection}>
      <SoleHeader title="FLOW" lead="ご利用の流れ" isTitleWhite="true" />
      <div className="columnComponent is-2column">
        {Data.flow.map(({ heading, text }, index) => (
          <section className="columnChild" key={heading}>
            <div
              className={s.topImgWrap + ' imgWrapComponent with-border heading'}
            >
              <FlowThumbnail index={index} />
            </div>
            <h4 className={s.topHeading + ' heading is-primary'}>{heading}</h4>
            <p>{text}</p>
          </section>
        ))}
      </div>
    </Section>
  )
}

function FlowThumbnail({ index }) {
  /**
   * this redundant code is for the sake of Gatsby image specification
   */

  const image0 = '../../../images/sole/flow01.png'
  const image1 = '../../../images/sole/flow02.png'
  const image2 = '../../../images/sole/flow03.png'
  const image3 = '../../../images/sole/flow04.png'

  return index === 0 ? (
    <StaticImage
      src={image0}
      alt="flow image"
      placeholder="blurred"
      layout="fullWidth"
    />
  ) : index === 1 ? (
    <StaticImage
      src={image1}
      alt="flow image"
      placeholder="blurred"
      layout="fullWidth"
    />
  ) : index === 2 ? (
    <StaticImage
      src={image2}
      alt="flow image"
      placeholder="blurred"
      layout="fullWidth"
    />
  ) : (
    <StaticImage
      src={image3}
      alt="flow image"
      placeholder="blurred"
      layout="fullWidth"
    />
  )
}
