import { StaticImage } from 'gatsby-plugin-image'
import { SoleHeader, Section } from '~/components'
import Data from '~/json/sole-features.json'
import { topHeading } from '~/components/pages/top/top.module.scss'
import * as s from '~/components/pages/sole/sole-features.module.scss'

export function SoleFeatures() {
  return (
    <Section color="gray">
      <SoleHeader title="FEATURES" lead="特徴" isTitleWhite="true" />

      <div className={s.features_columns + ' columnComponent is-2column'}>
        {Data.features.map(({ heading, text }, index) => (
          <section className="columnChild" key={heading}>
            <div
              className={
                s.example_img + ' imgWrapComponent with-border heading'
              }
            >
              {index === 0 ? (
                <StaticImage
                  src="../../../images/sole/features01.png"
                  alt={heading}
                  placeholder="blurred"
                  layout="fullWidth"
                />
              ) : index === 1 ? (
                <StaticImage
                  src="../../../images/sole/features02.png"
                  alt={heading}
                  placeholder="blurred"
                  layout="fullWidth"
                />
              ) : index === 2 ? (
                <StaticImage
                  src="../../../images/sole/features03.png"
                  alt={heading}
                  placeholder="blurred"
                  layout="fullWidth"
                />
              ) : (
                <StaticImage
                  src="../../../images/sole/features04.png"
                  alt={heading}
                  placeholder="blurred"
                  layout="fullWidth"
                />
              )}
            </div>
            <h4
              className={
                topHeading + ' heading is-primary with-underline white'
              }
            >
              <span dangerouslySetInnerHTML={{ __html: heading }} />
            </h4>
            <p>{text}</p>
          </section>
        ))}
      </div>
    </Section>
  )
}
