import { StaticImage } from 'gatsby-plugin-image'
import { Section } from '~/components'
import * as s from '~/components/pages/wrap/wrap-first-view.module.scss'
import Logo from '../../../images/mv_logo_bk.svg'
import SneakersSVG from '../../../images/wrap/text_sneakers.svg'
import WrapSVG from '../../../images/wrap/text_wrap.svg'
import { motion } from 'framer-motion'
import { useWindowSize } from 'react-use'
import { WINDOW_THRESHOLD } from '../../../lib/const'

const EASING = [0, 0.71, 0.2, 1.01]

function MotionText() {
  const isTablet = useWindowSize().width < WINDOW_THRESHOLD.xl

  const textVariants = {
    initial: isTablet ? { opacity: 0, y: 30 } : { opacity: 0, x: -30 },
    animate: isTablet ? { opacity: 1, y: 0 } : { opacity: 1, x: 0 },
    transition: {
      duration: 0.6,
      ease: EASING,
    },
  }

  return (
    <>
      <motion.h2
        variants={textVariants}
        initial="initial"
        animate="animate"
        transition={{ ...textVariants.transition, delay: 0.4 }}
        className={s.fv_header}
      >
        大切なスニーカーを守りたい
      </motion.h2>
      <motion.p
        variants={textVariants}
        initial="initial"
        animate="animate"
        transition={{ ...textVariants.transition, delay: 0.6 }}
        className={s.fv_lead}
      >
        靴職人が一足一足丁寧に、
        <br />
        靴の個性に合わせてラッピングを行う
        <br />
        スニーカーラップ サービスです。
      </motion.p>
    </>
  )
}

export function WrapFirstView() {
  const isTablet = useWindowSize().width < WINDOW_THRESHOLD.xl

  const imageVariants = {
    initial: { opacity: 0, x: -30, y: 80 },
    animate: { opacity: 1, x: 0, y: 0 },
    transition: {
      duration: 2.0,
      ease: EASING,
    },
  }

  const rightContainerVariants = {
    initial: {
      clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)',
    },
    animate: {
      clipPath: isTablet
        ? 'polygon(0% 25%, 100% 0, 100% 100%, 0% 100%)'
        : 'polygon(33% 0%, 100% 0%, 100% 100%, 0% 100%)',
    },
    transition: {
      duration: 1.4,
      ease: EASING,
    },
  }

  const bgVariants = {
    animate: { x: 0, opacity: 1 },
    transition: {
      duration: 2.0,
      ease: EASING,
    },
  }

  // extend bgVariants
  const bgLeftVariants1 = {
    ...bgVariants,
    initial: { x: isTablet ? '-100%' : '110%', opacity: 0 },
  }
  const bgLeftVariants2 = {
    ...bgVariants,
    initial: { x: isTablet ? '100%' : '-100%', opacity: 0 },
  }
  return (
    <Section
      color="none"
      padding="none"
      width="full"
      className={s.fv_container}
    >
      <div id="mainVisual" className={s.mainVisual}>
        <div className={s.main_visual_container}>
          <motion.span
            variants={imageVariants}
            initial="initial"
            animate="animate"
            transition={{ ...imageVariants.transition, delay: 0.8 }}
            className={s.main_visual_img_container}
          >
            <StaticImage
              src="../../../images/wrap/wrap-main-visual.png"
              alt="first view"
              objectFit="contain"
              className={s.main_visual_img}
            />
          </motion.span>
        </div>
        <div className={s.left_container}>
          <div className={s.left_container_inner}>
            <MotionText />
          </div>
          <motion.span
            variants={bgLeftVariants1}
            initial="initial"
            animate="animate"
            transition={{ ...bgLeftVariants1.transition, delay: 0.2 }}
            className={`${s.animated_bg_text} ${s.bg_text_left}`}
          >
            <SneakersSVG />
          </motion.span>
        </div>
        <motion.div
          variants={rightContainerVariants}
          initial="initial"
          animate="animate"
          transition={{ ...rightContainerVariants.transition, delay: 0.2 }}
          className={s.right_container}
        >
          <motion.span
            variants={bgLeftVariants2}
            initial="initial"
            animate="animate"
            transition={{ ...bgLeftVariants1.transition, delay: 0.2 }}
            className={`${s.animated_bg_text} ${s.bg_text_right}`}
          >
            <WrapSVG />
          </motion.span>
        </motion.div>
        <div className={s.sp_text_container}>
          <MotionText />
        </div>
        <div className={s.mainVisualTitle}>
          <p>あなたの大切なスニーカーを加水分解から守ります</p>
          <div role="img" aria-label="SNISH">
            <Logo />
          </div>
        </div>
      </div>
    </Section>
  )
}
