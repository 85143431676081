import { Link } from 'gatsby'
import { SnsFollow } from '~/components'
import Logo from './common_logo_wh.svg'
import LineIcon from '~/images/common_icon_line.svg'
import MailIcon from '~/images/common_icon_mail.svg'
import * as s from './nav.module.scss'

export function Footer({ isSpecialPage, isWrapOrderPage }) {
  return (
    <footer className={s.footer}>
      <div>
        <div className={s.footerLogo} role="img" aria-label="SNISH">
          {isWrapOrderPage || isSpecialPage ? (
            <Link to="/" className={s.extra_footer_logo}>
              <Logo />
              <span className="font-default">ブランドECサイトへ</span>
            </Link>
          ) : (
            <Logo />
          )}
        </div>
        <div className="shareIcons">
          <ul className={s.footerSns}>
            <SnsFollow />
          </ul>
        </div>
        <ul className={s.footerBtnWrap}>
          <li>
            <a
              href="/contact/"
              target="_blank"
              rel="noopener noreferrer"
              className="btn is-primary"
            >
              <span>
                <MailIcon />
              </span>
              <span>お問い合わせ</span>
            </a>
          </li>
          <li>
            <a
              href="https://line.me/R/ti/p/%40638iacxo"
              target="_blank"
              rel="noopener noreferrer"
              className="btn is-primary"
            >
              <span>
                <LineIcon />
              </span>
              <span>LINEで相談する</span>
            </a>
          </li>
        </ul>
        <nav className={s.footerGnav}>
          <ul>
            <li>
              <a href="https://shpree-snish.myshopify.com/pages/company">
                会社概要
              </a>
            </li>
            <li>
              <a href="https://shpree-snish.myshopify.com/policies/terms-of-service">
                ご利用規約
              </a>
            </li>
            <li>
              <a href="https://shpree-snish.myshopify.com/policies/legal-notice">
                特定取引法
              </a>
            </li>
            <li>
              <a href="https://shpree-snish.myshopify.com/pages/compensation">
                賠償基準
              </a>
            </li>
            <li>
              <a href="https://shpree-snish.myshopify.com/policies/privacy-policy">
                個人情報保護方針
              </a>
            </li>
            <li>
              <Link to="/contact">お問い合わせ</Link>
            </li>
            <li>
              <Link to="/blog">メディア掲載事例</Link>
            </li>
          </ul>
        </nav>
        <aside>
          <small>copyright &copy; SNISH.inc All Rights Reserved</small>
        </aside>
      </div>
    </footer>
  )
}
