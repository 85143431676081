import { useStaticQuery, graphql, Link } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import parse from 'html-react-parser'
import * as s from '~/components/pages/blog/blog.module.scss'

export function RelatedPost({ slug }) {
  const { allWpPost } = useStaticQuery(
    graphql`
      query {
        allWpPost {
          edges {
            node {
              date(formatString: "YYYY-MM-DD")
              title
              uri
              categories {
                nodes {
                  name
                  slug
                }
              }
              featuredImage {
                node {
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        quality: 100
                        placeholder: BLURRED
                        layout: FULL_WIDTH
                      )
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  )

  const filterArticle = allWpPost.edges.filter((element, index, array) =>
    [slug].includes(element.node.categories.nodes[0].slug)
  )

  return (
    <div className={s.articleRelatedList}>
      {filterArticle.length >= 1 ? (
        filterArticle.slice(0, 4).map(({ node }) => {
          const title = node.title
          const featuredImage = {
            image:
              node.featuredImage?.node?.localFile?.childImageSharp
                ?.gatsbyImageData,
            alt: node.featuredImage?.node?.alt || ``,
          }

          return (
            <article
              className={s.articleItem}
              itemScope
              itemType="http://schema.org/Article"
              key={node.uri}
            >
              <Link to={`/blog${node.uri}`} itemProp="url">
                <div className={s.thumbnail}>
                  {featuredImage?.image ? (
                    <GatsbyImage
                      image={featuredImage.image}
                      alt={featuredImage.alt}
                    />
                  ) : (
                    <StaticImage
                      src="../../../images/top_mv_pc.jpg"
                      alt=""
                      placeholder="blurred"
                      layout="fullWidth"
                    />
                  )}
                </div>
                <section className={s.articleDetail}>
                  <header>
                    <h3 className={s.articleTitle + ' heading is-secondary'}>
                      <span itemProp="headline">{parse(title)}</span>
                    </h3>
                  </header>
                  <small className={s.articleDate + ' heading'}>
                    {node.date} UP
                  </small>
                </section>
              </Link>
            </article>
          )
        })
      ) : (
        <p>関連記事はありません</p>
      )}
    </div>
  )
}
