// extracted by mini-css-extract-plugin
export var animated_bg_text = "wrap-first-view-module--animated_bg_text--lF2my";
export var bg_text_left = "wrap-first-view-module--bg_text_left--tv+K5";
export var bg_text_right = "wrap-first-view-module--bg_text_right--s0urr";
export var fadeIn = "wrap-first-view-module--fadeIn--ys1Fn";
export var fv_container = "wrap-first-view-module--fv_container--aHDgD";
export var fv_header = "wrap-first-view-module--fv_header--oU5pm";
export var fv_lead = "wrap-first-view-module--fv_lead--JEVOb";
export var left_container = "wrap-first-view-module--left_container--0UTrG";
export var left_container_inner = "wrap-first-view-module--left_container_inner--JG5tl";
export var mainVisual = "wrap-first-view-module--mainVisual--H388C";
export var mainVisualTitle = "wrap-first-view-module--mainVisualTitle--kzCEl";
export var main_visual_container = "wrap-first-view-module--main_visual_container--u6RBc";
export var main_visual_img = "wrap-first-view-module--main_visual_img---Abxr";
export var main_visual_img_container = "wrap-first-view-module--main_visual_img_container--jqVZK";
export var right_container = "wrap-first-view-module--right_container--lEmLe";
export var sp_text_container = "wrap-first-view-module--sp_text_container--ayNUc";