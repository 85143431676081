import { useStaticQuery, graphql } from 'gatsby'
import { useLocation } from '@reach/router'
import Helmet from 'react-helmet'
import { Header, Footer } from '~/components'

const InnerLayout = ({ isHomePage, children }) => {
  const {
    wp: {
      generalSettings: { title },
    },
  } = useStaticQuery(graphql`
    query LayoutQuery {
      wp {
        generalSettings {
          title
          description
        }
      }
    }
  `)

  const path = useLocation().pathname
  const isSpecialPage =
    path === '/wrap/' ||
    path === '/wrap' ||
    path === '/sole/' ||
    path === '/sole'
  const isWrapOrderPage =
    path === '/wrap/order/' ||
    path === '/wrap/order' ||
    path === '/sole/order/' ||
    path === '/sole/order'

  return (
    <>
      <Helmet>
        <script type="application/javascript">
          {`
            {
              (function(d) {
                var config = {
                  kitId: 'ocx1wmy',
                  scriptTimeout: 3000,
                  async: true
                },
                h=d.documentElement,t=setTimeout(function(){h.className=h.className.replace(/\bwf-loading\b/g,"")+" wf-inactive";},config.scriptTimeout),tk=d.createElement("script"),f=false,s=d.getElementsByTagName("script")[0],a;h.className+=" wf-loading";tk.src='https://use.typekit.net/'+config.kitId+'.js';tk.async=true;tk.onload=tk.onreadystatechange=function(){a=this.readyState;if(f||a&&a!="complete"&&a!="loaded")return;f=true;clearTimeout(t);try{Typekit.load(config)}catch(e){}};s.parentNode.insertBefore(tk,s)
              })(document);
            }
          `}
        </script>
        <script
          async
          src="https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js"
        ></script>
        <link
          href="https://sdks.shopifycdn.com/buy-button/latest/buybutton.css"
          rel="stylesheet"
          type="text/css"
        />
        <link
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick.min.css"
          rel="stylesheet"
          type="text/css"
        />
        <link
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick-theme.min.css"
          rel="stylesheet"
          type="text/css"
        />
      </Helmet>
      <div className="global-wrapper" data-is-root-path={isHomePage}>
        <Header
          siteTitle={title}
          isHomePage={isHomePage}
          isSpecialPage={isSpecialPage}
          isWrapOrderPage={isWrapOrderPage}
        />

        <main className="main" role="main">
          {children}
        </main>

        <Footer
          isSpecialPage={isSpecialPage}
          isWrapOrderPage={isWrapOrderPage}
        />
      </div>
    </>
  )
}
export default InnerLayout
