import { StaticImage } from 'gatsby-plugin-image'
import { SoleHeader, Section, Video } from '~/components'
import { topHeading } from '~/components/pages/top/top.module.scss'
import * as s from '~/components/pages/sole/sole-merit.module.scss'
import Data from '~/json/sole-merits.json'
import Press from '../../../videos/sole/press.mp4'
import PressThumbnail from '../../../videos/sole/press.png'

export function SoleMerit() {
  return (
    <Section className={s.merit_container}>
      <div className={s.merit_text_container}>
        <h2 className="text-accent">
          スニーカーのこんなお悩み
          <br className="visible-phone" />
          ありませんか？
        </h2>
        <ul>
          <li>かかとだけの補強をしていたが全体の補強をしたい</li>
          <li>
            ソールが削れてお気に入りのスニーカーを泣く泣く捨てた経験がある
          </li>
          <li>デザイン性を損なわないソールを張ってほしい</li>
        </ul>
      </div>
      <div className={s.merit_wrapper}>
        <SoleHeader title="MERIT" lead="職人の手によるメリットとは？" />
        <div className={s.merit_columns + ' columnComponent is-2column'}>
          {Data.merits.map(({ heading, text }, index) => (
            <section className="columnChild" key={heading}>
              <div
                className={
                  s.example_img + ' imgWrapComponent with-border heading'
                }
              >
                {index === 0 ? (
                  <StaticImage
                    src="../../../images/sole/merit01.png"
                    alt={heading}
                    placeholder="blurred"
                    layout="fullWidth"
                  />
                ) : (
                  <StaticImage
                    src="../../../images/sole/merit02.png"
                    alt={heading}
                    placeholder="blurred"
                    layout="fullWidth"
                  />
                )}
              </div>
              <h4 className={topHeading + ' heading is-primary with-underline'}>
                <span dangerouslySetInnerHTML={{ __html: heading }} />
              </h4>
              <p>{text}</p>
            </section>
          ))}
        </div>
      </div>
      <div className={s.merit_technology}>
        <div className={s.merit_technology_wrap}>
          <h3>
            ドイツ製の靴製造専用プレス機「GP-SPECIAL
            PRESS」を使用した、ソール接着技術
          </h3>
          <div className={s.merit_technology_inner}>
            <StaticImage
              src="../../../images/sole/merit03.png"
              alt="ドイツ製の靴製造専用プレス機「GP-SPECIAL PRESS」"
              objectFit="contain"
              className={s.merit_technology_image}
            />
            <div className={s.merit_technology_desc}>
              <p>
                ソール接着は、ドイツ製の靴製造専用プレス機「GP-SPECIAL
                PRESS」で行います。
                <br />
                「GP-SPECIAL
                PRESS」は、靴作りで有名なドイツの靴専門店では導入されることが多いですが、日本では数えるほどの靴専門店でしか導入されていない非常に希少なモデルです。
              </p>
              <p>
                靴製造プレス機によるソール接着では靴と足型に沿って均等に圧をかけるため、隙間なく綺麗に接着することが可能。
                <br />
                隙間から徐々に剥がれていくということがないので、安心して靴を履き続けることができます。
              </p>
              <div className={s.merit_technology_use}>
                <h4>
                  <span className={s.merit_technology_icon_cross}>
                    <StaticImage
                      src="../../../images/sole/icon-cross.png"
                      objectFit="contain"
                    />
                  </span>
                  靴製造専用プレス機を使用しない場合
                </h4>
                <p>
                  隙間なく接着するためにソールをハンマーで叩く方法が一般的ですが、この方法の場合、スニーカー本体も叩いて傷つけてしまう可能性があり接着にもムラができます。
                </p>
                <h4>
                  <span className={s.merit_technology_icon_circle}>
                    <StaticImage
                      src="../../../images/sole/icon-circle.png"
                      objectFit="contain"
                    />
                  </span>
                  靴製造専用プレス機を使用した場合
                </h4>
                <p>
                  真上からプレスして接着するためスニーカー本体を痛める心配もありません。また、接着剤は靴職人の声からできた独自製法の靴修理屋専用の生ボンドを使用。乾燥速度と粘着性が非常に高いため、
                  <strong>従来の接着剤よりも約3倍の剥がれにくさ</strong>
                  を実現しています。
                </p>
              </div>
              <Video src={Press} thumbnail={PressThumbnail} />
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}
