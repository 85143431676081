import { Link } from 'gatsby'
import PaginateArrowL from '~/images/paginate_icon_arrow_l.svg'
import PaginateArrowR from '~/images/paginate_icon_arrow_r.svg'

export function Pagination({
  pageContext: { previousPagePath, nextPagePath },
}) {
  return (
    <div className="paginationComponent">
      <nav className="contentComponent mincho">
        {previousPagePath && (
          <Link to={previousPagePath}>
            <span>
              <PaginateArrowL />
            </span>{' '}
            Prev Page
          </Link>
        )}
        {nextPagePath && (
          <Link to={nextPagePath}>
            Next Page{' '}
            <span>
              <PaginateArrowR />
            </span>
          </Link>
        )}
      </nav>
    </div>
  )
}
