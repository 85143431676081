import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import * as s from '~/components/pages/top/top.module.scss'

export function Plan() {
  return (
    <>
      <header>
        <h3
          className={
            s.topSectionTitle + ' contentComponent heading with-english'
          }
        >
          <span>料金プラン</span>PLAN
        </h3>
      </header>
      <div className="contentComponent columnComponent is-2column">
        <div className={s.planContent + ' columnChild planColumnChild'}>
          <Link to="/order/">
            <StaticImage
              src="../../../images/top/top_plan_wash.jpg"
              alt=""
              placeholder="blurred"
              layout="fullWidth"
            />
          </Link>
          <h4 className={s.planHeading + ' heading is-primary'}>
            <Link to="/order/">スニーカーウォッシュ</Link>
          </h4>
          <p>
          優しくブラッシングを施し粗汚れを落とした後、品質にこだわった洗剤（※スニーカーの素材に合わせた天然由来の洗剤を使用します。また刺激の強い成分を使用していない為、スニーカーへの負担が少ないのも特徴です）で綺麗に洗い上げていきます。機械仕上げではなく、一足一足丁寧に職人が手洗いをするので、大切なスニーカーを傷める心配はありません（※ウイルス殺菌・抗菌・脱臭のオプションプランも靴を傷めない靴専用機械を導入しております）。
          </p>
          <Link to="/order/">
            <table>
              <tbody>
                <tr>
                  <th>スニーカーウォッシュ</th>
                  <td>3,300円（税込）</td>
                </tr>
              </tbody>
            </table>
          </Link>
        </div>
        <div className={s.planContent + ' columnChild planColumnChild'}>
          <Link to="/order/">
            <StaticImage
              src="../../../images/top/top_plan_repair.jpg"
              alt=""
              placeholder="blurred"
              layout="fullWidth"
            />
          </Link>
          <h4 className={s.planHeading + ' heading is-primary'}>
            <Link to="/order/">修理</Link>
          </h4>
          <p>
            それぞれの修理箇所に合うお色で対応させていただきます。お色のご希望などございましたら、お申し込み画面の備考欄でお申し付けください。
          </p>
          <Link to="/order/">
            <table>
              <tbody>
                <tr>
                  <th>かかと修理</th>
                  <td>5,500円（税込）</td>
                </tr>
                <tr>
                  <th>オールソール</th>
                  <td>14,300円（税込）</td>
                </tr>
              </tbody>
            </table>
          </Link>
        </div>
        <div className={s.planContent + ' columnChild planColumnChild'}>
          <Link to="/order/">
            <StaticImage
              src="../../../images/top/top_plan_storage.jpg"
              alt=""
              placeholder="blurred"
              layout="fullWidth"
            />
          </Link>
          <h4 className={s.planHeading + ' heading is-primary'}>
            <Link to="/order/">保管</Link>
          </h4>
          <p>
            あなたの大切なスニーカーを最適な環境下でお預かり致します。出し入れは、スマホから自由自在。「綺麗にしてから保管コース」とより良い状態で保管するために独自の「スニーカーラップ」をしてから保管することも可能ですので、お好きなプランをお選びください。
          </p>
          <Link to="/order/">
            <table>
              <tbody>
                <tr>
                  <th>保管</th>
                  <td>１足あたり　660円（税込）/月＋オプション</td>
                </tr>
              </tbody>
            </table>
          </Link>
        </div>
        <div className={s.planContent + ' columnChild planColumnChild'}>
          <Link to="/wrap/">
            <StaticImage
              src="../../../images/top/top_plan_wrap.jpg"
              alt=""
              placeholder="blurred"
              layout="fullWidth"
            />
          </Link>
          <h4 className={s.planHeading + ' heading is-primary'}>
            <Link to="/wrap/">スニーカーラップ</Link>
          </h4>
          <p>
          大切なスニーカーを守りたい。あなたのスニーカーを加水分解から守るために、プロの職人がラップします。ラップする前に靴専用機で目に見えないカビの元を殺菌し、スニーカー内の水分除去を施したあと、１足１足を丁寧にラッピングしていきます。コレクションする際の角度も意識して施しますので、お手元に届いた際の仕上がりをお楽しみください。
          </p>
          <Link to="/wrap/">
            <table>
              <tbody>
                <tr>
                  <th>1足単品プラン</th>
                  <td>2,970円（税込）</td>
                </tr>
                <tr>
                  <th>3足セットプラン</th>
                  <td>7,920円（税込）</td>
                </tr>
                <tr>
                  <th>5足セットプラン</th>
                  <td>11,880円（税込）</td>
                </tr>
              </tbody>
            </table>
          </Link>
        </div>
        <div className={s.planContent + ' columnChild planColumnChild'}>
          <Link to="/sole/">
            <StaticImage
              src="../../../images/top/top_plan_sole.jpg"
              alt=""
              placeholder="blurred"
              layout="fullWidth"
            />
          </Link>
          <h4 className={s.planHeading + ' heading is-primary'}>
            <Link to="/sole/">ソール補強</Link>
          </h4>
          <p>
          愛着あるスニーカーを長く履くための、オールソール補強です。ソールに合わせて厚さやカラーもお選びください。
          </p>
          <Link to="/sole/">
            <table>
              <tbody>
                <tr>
                  <th>オールソール補強1mm</th>
                  <td>6,600円（税込）</td>
                </tr>
                <tr>
                  <th>ホワイト/ブラック オールソール補強6.5mm</th>
                  <td>7,150円（税込）</td>
                </tr>
              </tbody>
            </table>
          </Link>
        </div>
      </div>
    </>
  )
}
