import { useEffect } from 'react'

export function ContactForm({ formId }) {
  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://js.hsforms.net/forms/shell.js'
    document.body.appendChild(script)

    script.addEventListener('load', () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: 'na1',
          portalId: '19651454',
          formId: formId,
          target: '#hubspotForm',
        })
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <div id="hubspotForm" style={{ minHeight: '450px' }}></div>
}
