// extracted by mini-css-extract-plugin
export var fadeIn = "sole-first-view-module--fadeIn--WhYzl";
export var fv_container = "sole-first-view-module--fv_container--LIFyn";
export var fv_header = "sole-first-view-module--fv_header--Uc0ae";
export var fv_header_wrap = "sole-first-view-module--fv_header_wrap--Tyvgv";
export var left_container = "sole-first-view-module--left_container--+QHce";
export var mainVisual = "sole-first-view-module--mainVisual--VVUKD";
export var mainVisualTitle = "sole-first-view-module--mainVisualTitle--eFwv2";
export var main_visual_after = "sole-first-view-module--main_visual_after--a4y4l";
export var main_visual_before = "sole-first-view-module--main_visual_before--SY1Gh";
export var main_visual_img = "sole-first-view-module--main_visual_img--RufpR";
export var main_visual_img_after = "sole-first-view-module--main_visual_img_after--p6RW9";
export var main_visual_img_before = "sole-first-view-module--main_visual_img_before--YjnMi";
export var right_container = "sole-first-view-module--right_container--hqbLE";