import { useEffect } from 'react'
import { WrapHeader, Section } from '~/components'
import Data from '~/json/wrap-plans.json'
import * as s from '~/components/pages/wrap/wrap-plan.module.scss'
import { ShopifyBuyInit } from '../../../lib/utils'

export function WrapPlan() {
  return (
    <Section color="gray">
      <WrapHeader title="PLAN" lead="プラン & 料金" />
      <div id="wrap_plan_wrapper" className={s.plan_wrapper}>
        {Data.plans.map(plan => (
          <PlanCard key={plan.title} plan={plan} />
        ))}
      </div>
    </Section>
  )
}

function PlanCard({ plan }) {
  const { title, price, points, buyId, elementId } = plan

  useEffect(() => {
    if (!window.location.href.includes('wrap') || typeof window === 'undefined')
      return

    ShopifyBuyInit({
      buyId: buyId,
      elementId: elementId,
      type: 'product',
      caption: false,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={`${s.plan_card} wrap_plan_card`}>
      <h5 className="text-accent">{title}</h5>
      <p className={s.plan_price + ' text-accent'}>
        {price}
        <span>（税込）</span>
      </p>
      <ul>
        {points.map(({ text }) => (
          <li key={text}>
            <span>{text}</span>
          </li>
        ))}
      </ul>
      <div id={elementId} className="wrap_plan_shopify_btn acordionContent" />
    </div>
  )
}
