import { useStaticQuery, graphql, Link } from 'gatsby'
import parse from 'html-react-parser'
import * as s from '~/components/pages/blog/blog.module.scss'

export function NewsList() {
  const { allWpPost } = useStaticQuery(
    graphql`
      query {
        allWpPost(limit: 3) {
          nodes {
            date(formatString: "YYYY-MM-DD")
            title
            uri
          }
        }
      }
    `
  )

  const posts = allWpPost.nodes

  return (
    <div className={s.articleLatestList}>
      {posts.length >= 1 ? (
        posts.map(post => {
          const title = post.title

          return (
            <article
              className={s.articleItem + ' contentComponent'}
              itemScope
              itemType="http://schema.org/Article"
              key={post.uri}
            >
              <Link to={`/blog${post.uri}`} itemProp="url" className="">
                <section className={s.articleDetail}>
                  <header className="heading">
                    <h2 className={s.articleTitle + ' heading is-secondary'}>
                      <span itemProp="headline">{parse(title)}</span>
                    </h2>
                  </header>
                  <small className={s.articleDate + ' heading is-primary'}>
                    {post.date}
                  </small>
                </section>
              </Link>
            </article>
          )
        })
      ) : (
        <p>新着記事はありません</p>
      )}
    </div>
  )
}
