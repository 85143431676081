import { StaticImage } from 'gatsby-plugin-image'
import { SoleHeader, Section, Video } from '~/components'
import { flowSection, topHeading } from '~/components/pages/top/top.module.scss'
import * as s from '~/components/pages/sole/sole-example.module.scss'
import Data from '~/json/sole-examples.json'
import Slider from 'react-slick'
import Ex01 from '../../../videos/sole/ex01.mp4'
import Ex01Thumbnail from '../../../videos/sole/ex01.png'
import Ex02 from '../../../videos/sole/ex02.mp4'
import Ex02Thumbnail from '../../../videos/sole/ex02.png'

const sliderOptions = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  pauseOnHover: true,
}

function ExampleSlider() {
  return (
    <Slider {...sliderOptions} className="with-arrows">
      <StaticImage
        src="../../../images/sole/ex-slide01-01.png"
        placeholder="blurred"
        layout="fullWidth"
      />
      <StaticImage
        src="../../../images/sole/ex-slide01-02.png"
        placeholder="blurred"
        layout="fullWidth"
      />
      <StaticImage
        src="../../../images/sole/ex-slide01-03.png"
        placeholder="blurred"
        layout="fullWidth"
      />
      <StaticImage
        src="../../../images/sole/ex-slide01-04.png"
        placeholder="blurred"
        layout="fullWidth"
      />
      <StaticImage
        src="../../../images/sole/ex-slide01-05.png"
        placeholder="blurred"
        layout="fullWidth"
      />
      <StaticImage
        src="../../../images/sole/ex-slide01-06.png"
        placeholder="blurred"
        layout="fullWidth"
      />
      <StaticImage
        src="../../../images/sole/ex-slide01-07.png"
        placeholder="blurred"
        layout="fullWidth"
      />
    </Slider>
  )
}

function ExampleSlider2() {
  return (
    <Slider {...sliderOptions} className="with-arrows">
      <StaticImage
        src="../../../images/sole/ex-slide02-01.png"
        placeholder="blurred"
        layout="fullWidth"
      />
      <StaticImage
        src="../../../images/sole/ex-slide02-02.png"
        placeholder="blurred"
        layout="fullWidth"
      />
      <StaticImage
        src="../../../images/sole/ex-slide02-03.png"
        placeholder="blurred"
        layout="fullWidth"
      />
      <StaticImage
        src="../../../images/sole/ex-slide02-04.png"
        placeholder="blurred"
        layout="fullWidth"
      />
      <StaticImage
        src="../../../images/sole/ex-slide02-05.png"
        placeholder="blurred"
        layout="fullWidth"
      />
      <StaticImage
        src="../../../images/sole/ex-slide02-06.png"
        placeholder="blurred"
        layout="fullWidth"
      />
      <StaticImage
        src="../../../images/sole/ex-slide02-07.png"
        placeholder="blurred"
        layout="fullWidth"
      />
      <StaticImage
        src="../../../images/sole/ex-slide02-08.png"
        placeholder="blurred"
        layout="fullWidth"
      />
    </Slider>
  )
}

export function SoleExample() {
  return (
    <Section className={flowSection} color="gray">
      <SoleHeader title="EXAMPLE" lead="実例紹介" isTitleWhite="true" />
      <div className={s.example_illustration + ' columnComponent is-2column'}>
        {Data.examples.map(({ heading, text }, index) => (
          <section
            className={`${s.sole_example_column} columnChild`}
            key={heading}
          >
            <div className={s.example_img + ' imgWrapComponent heading'}>
              {index === 0 ? <ExampleSlider /> : <ExampleSlider2 />}
            </div>
            <h4 className={topHeading + ' heading is-primary-l'}>{heading}</h4>
            <p>{text}</p>
          </section>
        ))}
      </div>
      <SoleHeader title="360° VIEW" isTitleWhite="true" />
      <div className="columnComponent is-2column">
        <div className={`${s.sole_example_column2} columnChild`}>
          <Video src={Ex01} thumbnail={Ex01Thumbnail} />
          <h4 className={s.example_heading + ' heading is-primary'}>
            1mm厚ソールの場合
          </h4>
        </div>
        <div className={`${s.sole_example_column2} columnChild`}>
          <Video src={Ex02} thumbnail={Ex02Thumbnail} />
          <h4 className={s.example_heading + ' heading is-primary'}>
            6.5mm厚ソールの場合
          </h4>
        </div>
      </div>
    </Section>
  )
}
