import { SoleHeader, Section, Faq } from '~/components'
import Questions from '~/json/faq.json'

export function SoleFaq() {
  const faq = Questions.sole_faq

  return (
    <Section>
      <SoleHeader title="FAQ" lead="よくある質問" />
      <Faq faq={faq} />
    </Section>
  )
}
