import { StaticImage } from 'gatsby-plugin-image'
export function Video({ src, thumbnail }) {
  return (
    <div className="video">
      <div
        className="video-thumbnail"
        style={{ backgroundImage: `url(${thumbnail})` }}
      ></div>
      <div className="video-play">
        <StaticImage
          src="../../images/icon_play.png"
          alt=""
          placeholder="blurred"
          layout="fullWidth"
        />
      </div>
      <video playsInline controls poster={thumbnail}>
        <source src={src} />
      </video>
    </div>
  )
}
