import { StaticImage } from 'gatsby-plugin-image'
import * as s from '~/components/pages/top/top.module.scss'

export function Features() {
  return (
    <>
      <header className="">
        <h3
          className={
            s.topSectionTitle + ' contentComponent heading with-english'
          }
        >
          <span>特徴</span>FEATURES
        </h3>
      </header>
      <dl className={s.topFeatureList + ' contentComponent'}>
        <div>
          <dt className="heading is-primary">スニーカーウォッシュ</dt>
          <dd>
            優しくブラッシングを施し粗汚れを落とした後、品質にこだわった洗剤（※スニーカーの素材に合わせた天然由来の洗剤を使用します。また刺激の強い成分を使用していない為、スニーカーへの負担が少ないのも特徴です）と鳥取の澄んだ水を使用し、綺麗に洗い上げていきます。
            <br />
            機械仕上げではなく、一足一足丁寧に職人が手洗いをするので、大切なスニーカーを傷める心配はありません（※ウイルス殺菌・抗菌・脱臭のオプションプランも靴を傷めない靴専用機械を導入しております）。
            <div className={s.imgWrap}>
              <StaticImage
                src="../../../images/top/top_features_wash.jpg"
                alt=""
                placeholder="blurred"
                layout="fullWidth"
              />
            </div>
          </dd>
        </div>
        <div>
          <dt className="heading is-primary">修理</dt>
          <dd>
            かかとの修理箇所に合うカラーで対応させていただきます。その他の修理はおまかせプランとなりますが、お客様の大切なスニーカーを安心してお預けいただけるよう、LINEなどを介して職人に直接ご相談していただけるシステムを導入しています。ご不安な点やご希望のお色などございましたらお気軽にお問い合わせください。
            <div className={s.imgWrap}>
              <StaticImage
                src="../../../images/top/top_features_repair.jpg"
                alt=""
                placeholder="blurred"
                layout="fullWidth"
              />
            </div>
          </dd>
        </div>
      </dl>
    </>
  )
}
