import { StaticImage } from 'gatsby-plugin-image'
import { WrapHeader, Section } from '~/components'
import { flowSection, topHeading } from '~/components/pages/top/top.module.scss'
import {
  example_img,
  wrap_example_column,
} from '~/components/pages/wrap/wrap-example.module.scss'
import Data from '~/json/wrap-examples.json'

export function WrapExample() {
  return (
    <Section className={flowSection}>
      <WrapHeader title="EXAMPLE" lead="実例紹介" />
      <div className="columnComponent is-2column">
        {Data.examples.map(({ heading, text }, index) => (
          <section
            className={`${wrap_example_column} columnChild`}
            key={heading}
          >
            <div
              className={example_img + ' imgWrapComponent with-border heading'}
            >
              {index === 0 ? (
                <StaticImage
                  src="../../../images/wrap/example01.jpg"
                  alt={heading}
                  placeholder="blurred"
                  layout="fullWidth"
                />
              ) : (
                <StaticImage
                  src="../../../images/wrap/example02.jpg"
                  alt={heading}
                  placeholder="blurred"
                  layout="fullWidth"
                />
              )}
            </div>
            <h4 className={topHeading + ' heading is-primary text-accent'}>
              {heading}
            </h4>
            <p>{text}</p>
          </section>
        ))}
      </div>
    </Section>
  )
}
